
import { defineComponent } from '@vue/runtime-core'
import { Task } from '../interfaces/Task'
import { deleteTask, getTask, updateTask } from '../services/TaskService'

export default defineComponent({
  data() {
    return {
      currentTask: {} as Task,
    }
  },
  methods: {
    async loadTask(id: string) {
      const res = await getTask(id)
      console.log(res)
      this.currentTask = res.data
    },
    async handleUpdate() {
      if(typeof this.$route.params.id === 'string') {
        await updateTask(this.$route.params.id, this.currentTask)
        this.$router.push({name: 'tasks'})
      }
    },
    async handleDelete() {
      if(typeof this.$route.params.id === 'string') {
        const res = await deleteTask(this.$route.params.id, this.currentTask)
        this.$router.push({name: 'tasks'})
      }
    }
  },
  mounted() {
    if(typeof this.$route.params.id === "string") this.loadTask(this.$route.params.id)
  }
})
