import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/HomeView.vue'
import TaskDetail from '../components/TaskDetail.vue'
import TaskForm from '../components/TaskForm.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    alias: '/tasks',
    name: 'tasks',
    component: HomeView,
  },

  {
    path: '/tasks/new',
    name: 'tasks-new',
    component: TaskForm,
  },

  {
    path: '/tasks/:id',
    name: 'tasks-details',
    component: TaskDetail,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router