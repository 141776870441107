
  import {defineComponent} from 'vue'
  
  export default defineComponent({
    data(){
      return {
        
      }
    },
    methods: {
      async go(){
        const req = await fetch('https://api.fifa.com/api/v3/seasons/255711?language=en');
        const res = await req.json();
        console.log(res);
      }
    },

    mounted() {
      this.go();
    }
  })
  