
import {defineComponent} from 'vue'

import {Task} from '../interfaces/Task'
import {createTask} from '../services/TaskService'

export default defineComponent({
  data(){
    return {
      task:  {} as Task
    }
  },
  methods: {
    async saveTask(){
      const res = await createTask(this.task)
      console.log(res)
      this.$router.push({name: 'tasks'})
    }
  }
})
