
import { defineComponent } from '@vue/runtime-core'
import NavBar from './components/NavBar.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
  }
});
